import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {httpClient} from "./http/httpClient";

function App() {
  const [healthCheckResponse, setHelthCheckResonse] = useState<string>();
  const [openSearchData, setOpenSearchData] = useState();
  const getHealthCheck = () => {
    httpClient.get("/api/v1/healthcheck").subscribe(r => setHelthCheckResonse(r.data));
  }
  const tryAuth = () => {
    httpClient.get("/api/v1/opensearch", {authenticated: true}).subscribe(r => setOpenSearchData(r.data));
  }
  // const login = (e: any) => {
  //   e.preventDefault()
  //   signIn({username: e.target[0].value, password: e.target[1].value}).subscribe(res => sessionStorage.setItem("token", res.data.accessToken))
  // }
    console.log(healthCheckResponse, openSearchData);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <button onClick={getHealthCheck}>TEST</button>
        <button onClick={tryAuth}>TEST AUTH</button>
        {/* <form onSubmit={login}>
          <input name={"username"}/>
          <input name={"password"}/>
          <button type={"submit"}>Login</button>
        </form> */}
      </header>
    </div>
  );
}

export default App;
