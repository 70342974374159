import {Article} from "../models/Article";
import { Link, useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { Divider, FormControl, MenuItem, Pagination, Select } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Public, PublicOff } from "@mui/icons-material";
import { SelectedAggregations } from "./aggregations";

interface ISearchResultsProps {
    results?: {
        total: {
            value: number
            },
        docs: Article[]
    }
    aggregations?:any
};

export const SearchResults = ({results, aggregations}: ISearchResultsProps, done:any) => {

    const [searchParams, setSearchParams ] = useSearchParams();
    const [page, setPage] = useState<number>(1);

    const searchResultMessage = useMemo(() => {
        const sortOrder = searchParams.get("sortOrder")
        if(sortOrder==="-PUBDATE"){return "Latest Publications"} else
        return "Search Result"
    }, [searchParams])
    
    useMemo(()=> setPage(Number(searchParams.get("from"))/(Number(searchParams.get("size"))||1) + 1), [searchParams])

    const handleChange = (event:any, value:any) => {
        searchParams.set("from", ((value-1)*Number(searchParams.get("size"))).toString())
        setPage(value)
        setSearchParams(searchParams)
    };

    const onChange = (event:any) => {
        searchParams.set("size", event.target.value.toString())
        searchParams.set("from", "0")
        setPage(1)
        setSearchParams(searchParams)
    };

    const PageSizeOptions = (props: {message?:string}) => {
        const sizeOptions = [5, 10, 25];
        return <>
        <Grid2 container alignItems={"top"} gap={2}> 
            <Grid2>
                 {props.message || ""} 
            </Grid2>
            <Grid2 xs> 
                <FormControl variant="standard">
                    <Select size="small" value={Number(searchParams.get("size"))} onChange={onChange} >
                        {sizeOptions.map(size => 
                        <MenuItem value={size} key={size}> {size} </MenuItem>)}
                    </Select>
                </FormControl> 
            </Grid2>
        </Grid2>
        </> 
    };

    const ResultList = () => {
        return <> {results?.docs.map(r => <div className="searchResultElement" key={r._id}> 
        {/* <Divider flexItem orientation="horizontal"/> */}
            <ul style={{paddingBottom:"1rem"}}>
                <li> <Link to={"/article/" + r._id}><h3 style={{ fontSize:20, marginBottom: "0.5rem"}}>{r.metadata?.titleEn? r.metadata.titleEn : r._id}</h3></Link></li>
                <li>  {r.metadata?.organization?.map((e)=> e[0]+ (e[1]? ", "+ e[1]: "")).join("; ")}  </li>
                <li> <small>   {r.metadata?.author?.join("; ")} </small></li>
                <li> <small>  {(r.metadata?.type? r.metadata.type: "") + (r.metadata?.year? ", " + r.metadata.year: "")} </small>  </li>
                <li style={{display: (!!r.metadata?.published? "inline-flex": "none"), alignItems:"center", gap:1}}> <small> {!!r?.metadata?.published===true? <> <Public fontSize="inherit"/>  Published </> : !!r.metadata?.published===false?  <> <PublicOff fontSize="inherit"/> Not Published </> : "" }</small> </li>
            </ul>
            <Divider variant="middle" style={{margin: "0 2rem 0 2rem"}} sx={{display: {xs: "box", sm: "none"}}}/>
        </div>) } </>
    }

    const resultMessage = () =>{
        const size = Number(searchParams.get("size"));
        const from = Number(searchParams.get("from"));
        

        return (!results?.total?.value || results?.total.value===0)? "No results for current search query" : 
        "Showing " +  (from + 1) +  "-"  +
        ((results?.total.value && size*page > results.total.value)? results?.total.value : size*(page|1))+  " of " + results?.total.value + " results"
    }
    
    return <>
    <Grid2 container justifyContent={{xs:"center", sm:"left"}} direction="column">
        <Grid2 sx={{display:{xs: "none", sm: "inline"}, paddingLeft: "2rem"}}> 
        <h2> {searchResultMessage} </h2>
        <i> {resultMessage()} </i>
        </Grid2>
        <Grid2 sx={{display:{xs: "inline", sm: "none"}}}> 
        <h2 style={{margin:"0"}}> SEARCH RESULT </h2>
        <p style={{margin:"0"}}> {resultMessage()}</p>
        </Grid2>
        <div>
        
        </div>
        <div className="searchResult" style={{display: results?.total.value? "inline": "none"}}> 
        <div className="selectedAggs"> <SelectedAggregations aggregations={aggregations}/> </div>
        
        <ResultList/>
        {/* <Divider style={{marginLeft:"2rem", marginRight: "2rem", marginBottom: "2rem"}}/> */}
        <Grid2 container justifyContent={"center"} alignItems={"center"} > 
            <Grid2 xs sx={{display:{xs: "none", sm: "flex"}, paddingLeft: "0.5rem"}}>
                <Pagination page={page} count={results?.total.value? Math.ceil(results.total.value/Number(searchParams.get("size"))) : 1} onChange={handleChange} boundaryCount={1}/>
            </Grid2>
            <Grid2 xs  sx={{display:{xs: "inline", sm: "none"}}}>
                <Pagination page={page} count={results?.total.value? Math.ceil(results.total.value/Number(searchParams.get("size"))) : 1} onChange={handleChange} boundaryCount={0} siblingCount={0}/>
            </Grid2>
            <Grid2  sx={{minWidth:"2rem", maxWidth:"20rem", paddingRight:"1rem", display:{xs: "none", sm: "inline"}}}>
                <PageSizeOptions message="Page Size"/>
            </Grid2>
            <Grid2 sx={{minWidth:"2rem", maxWidth:"20rem", display:{xs: "inline", sm: "none"}}} className= "pageSize">
                <PageSizeOptions/>
            </Grid2>
        </Grid2>

        </div>
        </Grid2>
    </>
}