import {Box, IconButton, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {Clear, Search} from "@mui/icons-material";

export const SearchBox = () => {
    const [searchParams, setSearchParams ] = useSearchParams();
    const [query, setQuery] = useState<string>("");

    useEffect(() => {
        setQuery(searchParams.get("query") || "")
    }, [searchParams])

    const clearFilter = () => {
        const filter: string[] = [];
        const size = searchParams.get("size")
        searchParams.forEach((k,v) => filter.push(v))

        for(const f of filter){
            searchParams.delete(f)
        }

        searchParams.set("size", size || "10")
    }

    const setSearch = () => {
        clearFilter()
        searchParams.set("query", query)
        searchParams.set("from", "0")
        setSearchParams(searchParams)
    };

    return <Box sx={{minWidth: {xs:"300px", sm:"500px"}, padding:"2.5% 0 2.5% 0", margin: {xs:"1.5rem 0 1.5rem 0", sm:"2.5rem 0 2.5rem 0" }}} className="searchBox">
        <span style={{ fontSize:18}}> <h4> Find research at ELTRP </h4></span>
        <form onSubmit={(e) => {e.preventDefault(); setSearch()}}>
        <TextField color="primary" value={query} focused variant="outlined" size="medium" fullWidth onChange={e => {setQuery(e.target.value)}}
                   InputProps={{endAdornment: <> <IconButton size={"large"} color={"primary"} onClick={()=>setQuery("")}> <Clear fontSize={"large"}/> </IconButton> <IconButton size="large" color="primary" type="submit"> <Search fontSize={"large"}/> </IconButton></>}}
        sx={{input:{fontSize:20},fontSize: "3rem", boxShadow:"0px 2px 8px 0px rgba(99, 99, 99, 0.3)"}}/>
        </form>
        </Box>
}