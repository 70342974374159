import { useContext, useEffect, useMemo, useState } from "react";
import { Article} from "../models/Article";
import { getArticle, getAutocomplete, updateArticle } from "./article.service";
import { Field, Form } from "react-final-form";
import { Button, TextField, Chip, Autocomplete, IconButton, Checkbox, FormControlLabel, CircularProgress, LinearProgress} from "@mui/material";
import {Add, Delete} from "@mui/icons-material"
import Grid2 from "@mui/material/Unstable_Grid2";
import { useParamsStable } from "../utils/hooks/use-params-stable";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays"
import { IAutocomplete } from "../models/Autocomplete";
import { useNavigate } from "react-router-dom";
import { HeaderELTRP} from "../layout/header-ELTRP";
import { UserContext } from "..";
import { useDebounce } from "../utils/hooks/debounce";

const Options = (name: string, active:any, query: string, lead?:string) => {
    const [autocomplete, setAutocomplete] = useState<IAutocomplete>();
    const debouncedQuery = useDebounce(query,300);
    
    useEffect(() => {
        if(active){
        getAutocomplete(name, debouncedQuery, lead).subscribe(a => {
            setAutocomplete(a)
        })}
    }, [debouncedQuery,name,lead,active])
    
    return autocomplete?.buckets.map(bucket => bucket.key) || []
}

const CheckBox = (props:{fieldName:string, label:string}) => {
    const [checked, setChecked] = useState<boolean>()
    return(
        <Field name={props.fieldName} type="checkbox">
            {({input, meta}) => (
                <FormControlLabel
                   control={<Checkbox checked={checked} color="secondary" onClick={()=>setChecked(!checked)} {...input}/>}
                    label={props.label}
                    sx={{m:1}}
                />)}
                
        </Field>)                                  
}

export const ArticleEditPage = () => {
    const id = useParamsStable("id");
    const [article, setArticle] = useState<Article>();
    const context = useContext(UserContext);
    const [loading, setLoading] = useState(false)
    const [loadingIn, setLoadingIn] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setLoadingIn(true)
        id && getArticle(id, context.handleError, setLoadingIn).subscribe(a => {setLoadingIn(false);setArticle(a)})
    },
        [id, context.handleError]
    )

    const submit = (updatedArticle: Article) => {
        updatedArticle = {...updatedArticle, metadata: {...updatedArticle.metadata }  }
        setLoading(true)
        id && article && updateArticle(id, updatedArticle, context.handleError, setLoading).subscribe(() => {setLoading(false); navigate(("/article/"+ id))});
    }

    const SingleField = (props: {fieldName: string, label: string, placeholder?:string}) => {
        return (
                <Field name={props.fieldName}>
                    {({ input, meta }) => (
                        <TextField 
                        color="secondary"
                            size="small" 
                            multiline 
                            margin="normal" 
                            variant="outlined"
                            placeholder={props.placeholder}
                            fullWidth 
                            label={props.label} 
                            InputLabelProps={{ shrink: true }} 
                            {...input} 
                        />)}
                </Field>
        )
    }

    const SingleFieldsAutocomplete = (props: {fieldName: string, termId: string, label: string, placeholder?:string}) => {
        return (
                <Field name={props.fieldName} subscription={{active:true, value:true}}>
                    {({ input, meta }) => (
                        <Autocomplete freeSolo options={Options(props.termId, meta.active, input.value)} renderInput={(params) =>
                            <TextField 
                            color="secondary"
                                margin="normal"  
                                variant="outlined" 
                                label={props.label} 
                                required
                                {...params} 
                                InputLabelProps={{ shrink: true }}  
                                size="small"  
                                placeholder={props.placeholder}
                                onChange={e => input.onChange(e.target.value) } 
                            />}
                        {...input} value={input.value||""} onChange={(event, value, reason) => input.onChange(value)} /> 
                    )}
                </Field>
        )
    }

    const MultipleFieldsAutocomplete = (props: { input: any, label: string[], termId: string, leadId:string , active:any, placeholder?:string[]}) => {

        const onChange = (index: number) => (value: any) => {
            const updated = [...props.input.value];
            updated[index] = value;
            props.input.onChange(updated);
        }

        return (
                <Grid2 container spacing={1}>
                    <Grid2 xs={6}> 
                        <Autocomplete freeSolo options={Options(props.termId, props.active, props.input.value?.[0])} renderInput={(params) =>
                                <TextField 
                                color="secondary"
                                    label={props.label[0]} 
                                    {...params} 
                                    size="small" 
                                    InputLabelProps={{ shrink: true }}  
                                    fullWidth
                                    required={!!props.input.value[1]}
                                    placeholder={props.placeholder?.[0]}
                                    key={props.input.name + "0"} 
                                    onChange={(e) => onChange(0)(e.target.value)} 
                                    margin="normal" 
                                />}
                            {...props.input} 
                            value={props.input.value[0]||""} 
                            onChange={(event, value, reason) =>  onChange(0)(value) } 
                        />
                    </Grid2>
                    <Grid2 xs={6}> 
                    <Autocomplete 
                            freeSolo options={Options(props.leadId, props.active, props.input.value?.[1], (props.input.value?.[0]||""))} renderInput={(params) =>
                                <TextField 
                                color="secondary"
                                    label={props.label[1]} 
                                    {...params} 
                                    size="small"  
                                    InputLabelProps={{ shrink: true }}  
                                    fullWidth
                                    placeholder={props.placeholder?.[1]}
                                    onChange={(e) => onChange(1)(e.target.value)} 
                                    key={props.input.name + "1"} 
                                    margin="normal" 
                                />}
                        {...props.input} 
                        value={props.input.value[1]||""} 
                        onChange={(event, value, reason) =>  onChange(1)(value) } 
                    />
                    </Grid2>
                </Grid2>
            )
    }

    const LinkField = (props: { input: any, active: any, label: string[], termId: string[] , index:any, placeholder?:string[]}) => {

        const onChange = (index: number) => (value: any) => {
            const updated = [...props.input.value];
            updated[index] = value;
            props.input.onChange(updated);
        }

        return (
            <Grid2 container spacing={1}>
                <Grid2 xs={4}> 
                        <Autocomplete freeSolo options={Options(props.termId[0], props.active, props.input.value?.[0])} renderInput={(params) =>
                                <TextField 
                                color="secondary"
                                    label={props.label[0]} 
                                    {...params} 
                                    size="small" 
                                    InputLabelProps={{shrink: true }}  
                                    fullWidth 
                                    required={props.input.value[1]||props.input.value[2]}
                                    placeholder={props.placeholder?.[0]}
                                    onChange={(e) => {return onChange(0)(e.target.value)}} 
                                    margin="normal" 
                                />}
                            {...props.input} 
                            value={props.input.value[0]||""}  
                            onChange={(event, value, reason) => {console.log(value); return onChange(0)(value)} } 
                        />
                    </Grid2>
                <Grid2 xs={4}>
                    <TextField 
                        label={props.label[1]} 
                        color="secondary"
                        {...props.input} 
                        size="small" 
                        InputLabelProps={{ shrink: true }}  
                        fullWidth 
                        required={props.input.value[0]||props.input.value[2]}
                        placeholder={props.placeholder?.[1]}
                        value={props.input.value[1]||""} 
                        onChange={(e) => onChange(1)(e.target.value)}  
                        margin="normal" 
                    />
                </Grid2>
                <Grid2 xs={4}> 
                        <Autocomplete freeSolo options={Options(props.termId[1], props.active, props.input.value?.[2])} renderInput={(params) =>
                                <TextField 
                                color="secondary"
                                    label={props.label[2]} 
                                    {...params} 
                                    onChange={(e) => {return onChange(2)(e.target.value)}}
                                    size="small"
                                    placeholder={props.placeholder?.[2]} 
                                    InputLabelProps={{ shrink: true }}  
                                    fullWidth 
                                    margin="normal" 
                                />}
                            {...props.input} 
                            value={props.input.value[2]||""} 
                            onChange={(event, value, reason) =>  onChange(2)(value) } 
                        />
                    </Grid2>
            </Grid2>
        )
    }

    const ChoiceFieldAutocomplete = (props: { input: any, label: string[], termId: string, leadId:string , active:any, placeholder?:string[]}) => {

        const onChange = (index: number) => (value: any) => {
            const updated = [...props.input.value];
            updated[index] = value;
            props.input.onChange(updated);
        }

        //const options = Options(props.termId, props.input.value?.[1])
        return (
            <Grid2 container spacing={1}>
                <Grid2 xs={6}> 
                        <Autocomplete freeSolo options={Options(props.leadId, props.active, props.input.value?.[0])} renderInput={(params) =>
                                <TextField
                                color="secondary" 
                                    label={props.label[0]} 
                                    {...params} 
                                    size="small" 
                                    InputLabelProps={{ shrink: true }}  
                                    fullWidth 
                                    required={!!props.input.value[1]}
                                    placeholder={props.placeholder?.[0]}
                                    key={props.input.name + "0"} 
                                    onChange={(e) => onChange(0)(e.target.value)} 
                                    margin="normal" 
                                />}
                            {...props.input} 
                            value={props.input.value[0]||""} 
                            onChange={(event, value, reason) =>  onChange(0)(value) } 
                        />
                    </Grid2>
                <Grid2 xs={6}> 
                <Autocomplete freeSolo options={Options(props.termId, props.active, props.input.value?.[1])} renderInput={(params) =>
                                <TextField 
                                    color="secondary"
                                    label={props.label[1]} 
                                    {...params} 
                                    size="small" 
                                    InputLabelProps={{ shrink: true }}  
                                    fullWidth 
                                    placeholder={props.placeholder?.[1]}
                                    key={props.input.name + "0"} 
                                    onChange={(e) => onChange(1)(e.target.value)} 
                                    margin="normal" 
                                />}
                            {...props.input} 
                            value={props.input.value[1]||""} 
                            onChange={(event, value, reason) =>  onChange(1)(value) } 
                        />
                    {/* <TextField => FOR CHICE WHEEL
                        //select 
                        //id="select" For entering data
                        label={props.label[1]} 
                        {...props.input} 
                        size="small" 
                        InputLabelProps={{ shrink: true }}  
                        fullWidth
                        value={props.input.value[1]||""}
                        key={props.input.name + "0"} 
                        onChange={(e) => onChange(1)(e.target.value)} 
                        margin="normal">
                             {
                                options.map((option)=> <MenuItem key={option} value={option}>
                                {option}
                                </MenuItem>)
                            } 
                        </TextField> 
                        */}
                    </Grid2>
            </Grid2>
        )
    }

    const FieldArrayLayout = (props: {textField:any, button: any, name:string}) => {
        return (
            <Grid2 container justifyContent={"flex-start"} alignItems={"center"}>
                <Grid2 xs key={"TextGrid" + props.name}> {props.textField} </Grid2>
                <Grid2 style={{minWidth:"2rem", maxWidth:"10rem"}} key={"ButtonGrid" + props.name}> {props.button} </Grid2>
            </Grid2>
        )
    }

    const initialValues = useMemo(() => {
        return {
            metadata: {
                ...article?.metadata,
                author: article?.metadata?.author && article?.metadata.author.length ? article?.metadata.author : [undefined],
                country: article?.metadata?.country && article?.metadata.country.length ? article?.metadata.country : [undefined],
                organization: article?.metadata?.organization && article?.metadata.organization.length ? article?.metadata.organization : [undefined],
                periodical: article?.metadata?.periodical && article?.metadata.periodical.length ? article?.metadata.periodical : [undefined],
                series: article?.metadata?.series && article?.metadata.series.length ? article?.metadata.series : [undefined],
                conference: article?.metadata?.conference && article?.metadata.conference.length ? article?.metadata.conference : [undefined],
                link: article?.metadata?.link && article?.metadata.link.length ? article?.metadata.link : [undefined],
                identifier: article?.metadata?.identifier && article?.metadata.identifier.length ? article?.metadata.identifier : [undefined],
                contributor: article?.metadata?.contributor && article?.metadata.contributor.length ? article?.metadata.contributor : [undefined],
                relation: article?.metadata?.relation && article?.metadata.relation.length ? article?.metadata.relation: [undefined],

        }}}, [article]);

    return<> 
    <div style={{height: "10px"}}> {loadingIn? <LinearProgress/>: null} </div>
    <header>
        <HeaderELTRP/>
    </header>
    
    <section className="editID">
        <div>
        <h1>Article {article?._id}</h1>
        </div>
    </section>
    
    <section className="editForm">
            <Form onSubmit={submit} initialValues={initialValues} mutators={{ ...arrayMutators }} render={({ handleSubmit, form: { mutators: { push } } }) => (
                <form onSubmit={handleSubmit}>

                    <div className="editGroup">
                        <CheckBox fieldName="metadata.published" label="Published"/>
                    </div>

                    <div className="editGroup">
                    {/* <SingleField fieldName="metadata.type" label="Article Type"/> */}
                    <SingleFieldsAutocomplete fieldName="metadata.type" label= "Article Type" termId="TYPE" placeholder="Type of article"/>
                    <SingleFieldsAutocomplete fieldName="metadata.language" label="Language" termId="LANGUAGE" placeholder="Language of article"/>
                    <SingleFieldsAutocomplete fieldName="metadata.year" label="Publish Year" termId="YEAR" placeholder="Year of publication"/>
                    </div>
                    
                    <div className="editGroup">
                    <FieldArrayLayout 
                        textField={<SingleField fieldName="metadata.titleEn" label="Title English" placeholder="English title (original or translated by operator)"/>} 
                        button={<CheckBox fieldName="metadata.titleTranslated" label="Translated"/>}
                        name="titleEn"/>
                    <SingleField fieldName="metadata.titleOther" label="Title Other" placeholder="Original title in other language (optional)"/>
                    </div>
                    
                    <div className="editGroup">
                    <FieldArrayLayout 
                        textField={<Field name="metadata.abstractEn">
                        {({ input, meta }) => (
                            <TextField size="small" color="secondary" InputLabelProps={{ shrink: true }} maxRows={10} multiline margin="normal" variant="outlined" fullWidth label={"Abstract English"} {...input} placeholder="Abstract from article in english" />
                        )}</Field>} 
                        button={<CheckBox fieldName="metadata.abstractTranslated" label="Translated"/>}
                        name="abstractEn"/>
                        <Field name="metadata.abstractOther">
                            {({ input, meta }) => (
                                <TextField size="small" color="secondary" InputLabelProps={{ shrink: true }} maxRows={10} multiline margin="normal" variant="outlined" fullWidth label={"Abstract Other"} {...input} placeholder="Abstract from article in original language if not in english" />
                            )}
                        </Field>
                        <Field name="metadata.summary">
                            {({ input, meta }) => (
                                <TextField size="small" color="secondary" InputLabelProps={{ shrink: true }} maxRows={10} multiline margin="normal" variant="outlined" fullWidth label={"Summary"} {...input} placeholder="Summary written by SDAB if abstract not available" />
                            )}
                        </Field>
                    </div>

                    <div className="editGroup"> 
                    <SingleField fieldName="metadata.commentExt" label="Comment External" placeholder="External comment"/>
                    <SingleField fieldName="metadata.commentInt" label="Comment Internal" placeholder="Internal comment"/>
                    </div>

                    <div className="editGroup">
                        <FieldArray name="metadata.author">
                            {({ fields }) => fields.map((name, index) => (
                                <div key={name}>
                                    <Field name={name} component="input" subscription={{active:true, value:true}}>
                                        {({ input, meta }) => (
                                            <FieldArrayLayout
                                            textField={<Autocomplete 
                                                freeSolo 
                                                options={Options("AUTHOR", meta.active, input.value)} 
                                                renderInput={(params) =>
                                                    <TextField 
                                                    color="secondary"
                                                        margin="normal" 
                                                        variant="outlined"
                                                        label={"Author " + (index + 1).toString()}
                                                        {...params}
                                                        fullWidth 
                                                        placeholder="Family name, Given name "
                                                        InputLabelProps={{ shrink: true }} 
                                                        size="small" 
                                                        value={input.value} 
                                                        onChange={value => input.onChange(value)}
                                                    />}
                                                {...input} 
                                                value={input.value} 
                                                onChange={(event, value, reason) => input.onChange(value)} />}
                                                button={<IconButton onClick={() => fields.remove(index)}> <Delete/> </IconButton>} 
                                                name="author"/>
                                        )}
                                    </Field>
                                </div>
                            ))}
                        </FieldArray>
                        <Grid2 xs={12}>
                            <Button variant="outlined" color="secondary" onClick={() => push("metadata.author", undefined)}> ADD NEW AUTHOR </Button>
                        </Grid2>
                    </div>

                    <div className="editGroup">
                        <FieldArray name="metadata.country">
                            {({ fields }) => fields.map((name, index) => (
                                <div key={name}>
                                    <Field name={name} component="input" subscription={{active:true, value:true}}>
                                        {({ input, meta }) => (
                                            <FieldArrayLayout
                                            textField={<Autocomplete 
                                                freeSolo 
                                                options={Options("COUNTRY", meta.active, input.value)} 
                                                renderInput={(params) =>
                                                    <TextField 
                                                    color="secondary"
                                                        margin="normal" 
                                                        variant="outlined"
                                                        label={"Country " + (index + 1).toString()}
                                                        {...params}
                                                        fullWidth 
                                                        placeholder="Country of publication"
                                                        InputLabelProps={{ shrink: true }} 
                                                        size="small" 
                                                        value={input.value} 
                                                        onChange={value => input.onChange(value)}
                                                    />}
                                                {...input} 
                                                value={input.value} 
                                                onChange={(event, value, reason) => input.onChange(value)} />}
                                                button={<IconButton onClick={() => fields.remove(index)}> <Delete/> </IconButton>}
                                                name="country" />
                                        )}
                                    </Field>
                                </div>
                            ))}
                        </FieldArray>
                        <Grid2 xs={12}>
                            <Button variant="outlined" color="secondary" onClick={() => push("metadata.country", undefined)}> ADD NEW COUNTRY </Button>
                        </Grid2>
                    </div>

                    <div className="editGroup">
                        <Grid2 container>
                        <FieldArray name="metadata.subject">
                            {({ fields }) => fields.value? fields.value.map((name, index) => (
                                <Chip 
                                    label={name} 
                                    style={{margin:"1% 1% 0% 0%"}}
                                    key={index}
                                    onDelete={() => fields.remove(index)} 
                                />
                            )): undefined}
                        </FieldArray>
                        </Grid2>
                        <Field name="newSubject" component="input" subscription={{active:true, value:true}}>
                            {({ input, meta }) => (
                                        <FieldArrayLayout 
                                        textField={<Autocomplete 
                                            freeSolo 
                                            options={Options("SUBJECT", meta.active, input.value)} 
                                            renderInput={(params) =>
                                                <TextField 
                                                color="secondary"
                                                    margin="normal" 
                                                    variant="outlined" 
                                                    label={"New Keyword"}
                                                    {...params} 
                                                    InputLabelProps={{ shrink: true }}  
                                                    size="small" 
                                                    placeholder=" Write or select keyword and press +"
                                                    value={input.value} 
                                                    onChange={value => input.onChange(value)} 
                                                />}
                                            {...input} 
                                            onChange={(event, value, reason) => input.onChange(value)} 
                                        />}
                                        button={<IconButton onClick={() =>  push("metadata.subject", input.value) }> <Add/> </IconButton>}
                                        name="subject"/>
                            )}
                        </Field>
                    </div>

                    <div className="editGroup">
                        <FieldArray name="metadata.organization">
                            {({ fields }) => fields.map((name, index) => (
                                <div key={name}>
                                    <Field name={name} component="input" subscription={{active:true, value:true}}>
                                        {({ input, meta }) => (
                                            <FieldArrayLayout 
                                            textField={ <MultipleFieldsAutocomplete input={input} label={["Organisation", "Unit"]} termId="ORGANIZATION_lead" leadId="ORGANIZATION" active={meta.active} placeholder={["English name of organization (local language acronyme)", "Name of unit (optional)"]} />}
                                            button={ <IconButton onClick={() => fields.remove(index)}> <Delete/> </IconButton>}
                                            name="organization"/>
                                        )}
                                    </Field>
                                </div>))}
                        </FieldArray>
                        <Button variant="outlined" color="secondary" onClick={() => push("metadata.organization", undefined)}> 
                            ADD NEW organisation
                        </Button>
                    </div>

                    <div className="editGroup">
                        <FieldArray name="metadata.periodical">
                            {({ fields }) => fields.map((name, index) => (
                                <div key={name}>
                                    <Field name={name} component="input" subscription={{active:true, value:true}}>
                                        {({ input, meta }) => (
                                            <FieldArrayLayout 
                                            textField={ <MultipleFieldsAutocomplete input={input} label={["Periodical", "Sequential"]} termId="PERIODICAL_lead" leadId="PERIODICAL" active={meta.active} placeholder={["Name of periodical","(mandatory if known)"]}/>}
                                            button={ <IconButton onClick={() => fields.remove(index)}> <Delete/> </IconButton>}
                                            name="periodical"/>
                                        )}
                                    </Field>
                                </div>))}
                        </FieldArray>
                        <Button variant="outlined" color="secondary" onClick={() => push("metadata.periodical", undefined)}> 
                            ADD NEW periodical
                        </Button>
                    </div>

                    <div className="editGroup">
                        <FieldArray name="metadata.series">
                            {({ fields }) => fields.map((name, index) => (
                                <div key={name}>
                                    <Field name={name} component="input" subscription={{active:true, value:true}}>
                                        {({ input, meta }) => (
                                            <FieldArrayLayout 
                                            textField={ <MultipleFieldsAutocomplete input={input} label={["Series", "Sequential"]} termId="SERIES_lead" leadId="SERIES" active={meta.active} placeholder={["Name of series", "(mandatory if known)"]}/>}
                                            button={ <IconButton onClick={() => fields.remove(index)}> <Delete/> </IconButton>}
                                            name="series"/>
                                        )}
                                    </Field>
                                </div>))}
                        </FieldArray>
                        <Button variant="outlined" color="secondary" onClick={() => push("metadata.series", undefined)}> 
                            ADD NEW series
                        </Button>
                    </div>

                    <div className="editGroup">
                        <FieldArray name="metadata.conference">
                            {({ fields }) => fields.map((name, index) => (
                                <div key={name}>
                                    <Field name={name} component="input" subscription={{active:true, value:true}}>
                                        {({ input, meta }) => (
                                            <FieldArrayLayout 
                                            textField={ <MultipleFieldsAutocomplete input={input} label={["Conference", "Sequential"]} termId="CONFERENCE_lead" leadId="CONFERENCE" active={meta.active} placeholder={["Name of conference or meeting", "(mandatory if known)"]}/>}
                                            button={ <IconButton onClick={() => fields.remove(index)}> <Delete/> </IconButton>}
                                            name="conference"/>
                                        )}
                                    </Field>
                                </div>))}
                        </FieldArray>
                        <Button variant="outlined" color="secondary" onClick={() => push("metadata.conference", undefined)}> 
                            ADD NEW conference
                        </Button>
                    </div>
                    
                    <div className="editGroup">
                        <FieldArray name="metadata.link">
                            {({ fields }) => fields.map((name, index) => (
                                <div key={name}>
                                    <Field name={name} component="input" subscription={{active:true, value:true}}>
                                        {({ input, meta }) => (
                                            <FieldArrayLayout
                                            textField={
                                            <LinkField index={index} label={["Access", "URL", "Service"]} termId={["LINK_access", "LINK_service"]} input={input} active={meta.active} placeholder={["(mandatory)", "Persistent URL link", "(optional)"]}/>
                                            }
                                            button={<IconButton onClick={() => fields.remove(index)}> <Delete/> </IconButton>}
                                            name="link"/>
                                        )}
                                    </Field>
                                </div>))}
                        </FieldArray>
                        <Button variant="outlined" color="secondary" onClick={() => push("metadata.link", undefined)}> ADD NEW URL </Button>
                    </div>

                    <div className="editGroup">
                        <FieldArray name="metadata.identifier">
                            {({ fields }) => fields.map((name, index) => (
                                <div key={name}>
                                    <Field name={name} component="input">
                                        {({ input, meta }) => (
                                            <FieldArrayLayout 
                                            textField={ <ChoiceFieldAutocomplete input={input} label={["Type", "Identifier"]} termId="IDENTIFIER_type" leadId="IDENTIFIER_lead" active={meta.active} placeholder={["Identification Type", "(mandatory)"]} />}
                                            button={ <IconButton onClick={() => fields.remove(index)}> <Delete/> </IconButton>}
                                            name="identifier"/>
                                        )}
                                    </Field>
                                </div>))}
                        </FieldArray>
                        <Button variant="outlined" color="secondary" onClick={() => push("metadata.identifier", undefined)}> 
                            ADD NEW identifier
                        </Button>
                    </div>

                    <div className="editGroup">
                        <FieldArray name="metadata.contributor">
                            {({ fields }) => fields.map((name, index) => (
                                <div key={name}>
                                    <Field name={name} component="input" subscription={{active:true, value:true}}>
                                        {({ input, meta }) => (
                                            <FieldArrayLayout 
                                            textField={ <ChoiceFieldAutocomplete input={input} label={["Contributor", "Type"]} termId="CONTRIBUTOR_type" leadId="CONTRIBUTOR_lead" active={meta.active} placeholder={["Name of Contributor", "(mandatory)"]}/>}
                                            button={ <IconButton onClick={() => fields.remove(index)}> <Delete/> </IconButton>}
                                            name="contributor"/>
                                        )}
                                    </Field>
                                </div>))}
                        </FieldArray>
                        <Button variant="outlined" color="secondary" onClick={() => push("metadata.contributor", undefined)}> 
                            ADD NEW CONTRIBUTOR
                        </Button>
                    </div>

                    <div className="editGroup">
                        <FieldArray name="metadata.relation">
                            {({ fields }) => fields.map((name, index) => (
                                <div key={name}>
                                    <Field name={name} component="input" subscription={{active:true, value:true}}>
                                        {({ input, meta }) => (
                                            <FieldArrayLayout 
                                            textField={ <MultipleFieldsAutocomplete input={input} label={["Relation", "Sort Key (alphanumeric)"]} termId="RELATION_lead" leadId="RELATION" active={meta.active} placeholder={["search-key", "(optional)"]} />}
                                            button={<></>}
                                            name="relation"/>
                                        )}
                                    </Field>
                                </div>))}
                        </FieldArray>
                    </div>

                    
                    
                    <Grid2> 
                    
                        <Button variant="contained" color="secondary" type="submit" sx={{mt:1, mb:1}}> {loading? <CircularProgress size={24} color="inherit"/>:  "Submit"} </Button>
                    </Grid2>
                </form>
            )} />
    </section>
    </>
}
