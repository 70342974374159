import {Close} from "@mui/icons-material"
import {Alert, Box, Button, CircularProgress, Dialog, Divider, IconButton, Paper} from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import {useContext, useEffect, useState} from "react"
import {Form} from "react-final-form"
import {useNavigate} from "react-router-dom"
import {UserContext} from ".."
import {postMultiPdf, createPdf} from "./pdf.service"

export const PdfUploadBox = ({open, setOpen}: any) => {

    const [file, setFile] = useState<any>();
    const [name, setName] = useState<string>();
    const context = useContext(UserContext);

    const [error, setError] = useState();
    const [loading, setLoading] = useState({l1: false, l2: false});

    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setFile(undefined);
                setLoading({l1: false, l2: false});
            }, 2000);
        }
    }, [error]);

    const handleChange = ({target}: any) => {
        setName(target.files[0].name);
        setFile(target.files[0]);
    }

    const handleClose = () => {
        setFile(undefined);
        setOpen(false)
    }

    const submit = () => {
        const formData = new FormData();
        formData.append("file", file);
        setLoading(prevState => ({...prevState, l1: true}))

        file && name && postMultiPdf(formData, context.handleError, setError).subscribe(a => {
            setLoading(prevState => ({...prevState, l1: false}))
            setFile(undefined);
            setOpen(false);
            navigate(("/article/" + a + "/edit"));
        });
    }

    const createArticle = () => {
        setLoading(prevState => ({...prevState, l2: true}))

        createPdf(context.handleError, setError).subscribe(a => {
            setLoading(prevState => ({...prevState, l2: false}))
            setOpen(false);
            navigate(("/article/" + a + "/edit"));
        })

    }

    return (
        <Dialog open={open} className="pdfBox">
            <Paper style={{padding: "0rem 0.5rem 3rem 0.5rem"}}>
                <Grid2 container justifyContent={"right"}>
                    <IconButton onClick={handleClose}> <Close/> </IconButton>
                </Grid2>
                <Grid2 textAlign="center">
                    <h2> Create a New Article</h2>
                </Grid2>
                <Grid2 container justifyContent={"center"} spacing={1} textAlign={"center"}>
                    <Grid2 xs={12}>
                        Select and upload a file from your computer to create a post
                    </Grid2>
                    <Form onSubmit={submit} render={({handleSubmit}) =>
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <Grid2>
                                <label htmlFor="upload">
                                    <input type="file" accept=".pdf" id="upload" style={{display: "none"}}
                                           onChange={handleChange}/>
                                    <Button variant="outlined" color="secondary" component="span">
                                        {file ? name : "Select file"}
                                    </Button>
                                </label>
                            </Grid2>
                            <Grid2>
                                <Box>
                                    {file?.size > (10 * 1024 * 1024) ? <Alert severity={"warning"}> File size too large </Alert> : null}
                                <Button fullWidth variant="contained" color="secondary" type="submit"
                                        disabled={!file || (file && file.size > (10 * 1024 * 1024))}> {loading.l1 ?
                                    <CircularProgress size={24} color="inherit"/> : "Create Post with PDF"}
                                </Button>
                                </Box>

                            </Grid2>
                        </form>
                    }>
                    </Form>
                </Grid2>
                <Divider style={{margin: "1.5rem"}}> OR </Divider>
                <Grid2 container justifyContent={"center"} spacing={1} textAlign={"center"}>
                    <Grid2 xs={12}>
                        Create a post without an attachment </Grid2>
                    <Grid2>
                        <Button variant="contained" color="secondary" onClick={createArticle}>
                            {loading.l2 ?
                                <CircularProgress size={24} color="inherit"/> : "Create Post without pdf"}
                        </Button>
                    </Grid2>
                </Grid2>
            </Paper>
        </Dialog>
    )
}

export const PdfUpload = () => {
    return (
        <section>
            <PdfUploadBox/>
        </section>
    )
}