import {Box, Divider, LinearProgress} from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import { useEffect, useState } from "react";
import { FooterELTRP } from "../layout/footer-ELTRP"
import { HeaderELTRP } from "../layout/header-ELTRP"
import { useLocation } from 'react-router-dom'
import { getDictionary } from "./dictionary.service";

const alphabet = ["6","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P",
    "Q","R","S","T","U","V","W","X","Y","Z"]

const DictionaryHeader = (props: {dictionaryJSON: any}) => {

    const onLetterClick = (e:any)=> {
        const s = document.getElementById(e) 
        s?.scrollIntoView({behavior:"smooth"})
    }

    return <Grid2 xs={12} container display="flex" justifyContent="center" textAlign={"center"}>
                <Grid2 xs={12}>
                    <h1> DICTIONARY</h1> 
                </Grid2>
                <Grid2 xs={12} style={{margin:"1rem 0 2rem 0"}}>
                    {alphabet.map(letter => <Box display={"inline-flex"} height={30} width={30} justifyContent="center" alignItems={"center"}> 
                    <small className={(props.dictionaryJSON && Object.keys(props.dictionaryJSON).map(m =>  m[0]).includes(letter))? "letterActive": "letterDisabled"} 
                    key={letter + "2"} onClick={()=> onLetterClick(letter)}> {letter} </small> </Box> )}
                    <br/>
                </Grid2>
            </Grid2>
}

const DictionaryElement = (props: {letterT:any, dictionaryJSON: any}) => {
    
    return <>
        <Grid2 xs={12}> 
            <h2 id={props.letterT}> <Divider> {props.letterT} </Divider></h2> 
        </Grid2>
    
        {Object.keys(props.dictionaryJSON).filter(k=> k.startsWith(props.letterT)).map(k => {
            return <Grid2 xs={10} sm={5} md={4} display="box" justifyContent="center" id={k} key={k} className="dictionaryElem">
                <h5> {k} </h5>
                <Divider variant="middle" sx={{display: {xs: "none", sm: "flex"}}}/>
                <Box display="inline-block" textAlign={"justify"}>
                <small> {props.dictionaryJSON[k]}</small>
                </Box>
            </Grid2>
         }) 
        }
    </>
}

const DictionaryBody = (props: {dictionaryJSON:any}) => {

    return <Grid2 xs={12} container display="flex" justifyContent="center">
            {Object.keys(props.dictionaryJSON).map(m => m[0]).filter((v,i,a)=> a.indexOf(v)===i).map((letter:string) => 
                <Grid2 sm={8} xs={12} columnGap={{xs: 10, lg:20}} rowGap={{xs: 2, lg: 5}} container display="flex" justifyContent="center" key={letter} textAlign="center">
                    <DictionaryElement letterT={letter} dictionaryJSON={props.dictionaryJSON}/>
                </Grid2>
            )}

        </Grid2>
}

export const Dictionary = () => {
   
    const [dictionaryJSON, setDictionaryJSON] = useState({})
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const state = location.state as any

    useEffect(()=>{
        setLoading(true)
        getDictionary(setLoading).subscribe(a => {
            setLoading(false)
            setDictionaryJSON(a)})
    },[state])

    useEffect(()=> {
        if(state !==null){
            const s = document.getElementById(state.subject) 
            s?.scrollIntoView({block:"center"})
            window.history.replaceState({}, document.title)
        }
    },[state, dictionaryJSON])

    return <>
        <div> {loading? <LinearProgress/> : null}</div>
    <header id={"top"}>
        <HeaderELTRP/>
    </header>
    <section className="dictionaryHeader">
        <DictionaryHeader dictionaryJSON={dictionaryJSON}/>
    </section>
    <section className="dictionaryBody">
        <DictionaryBody dictionaryJSON={dictionaryJSON}/>
        <Grid2 xs={12} textAlign={"center"} mt={5}> <p  style={{color:"black", cursor:"pointer"}} onClick={()=>document.getElementById("top")?.scrollIntoView({behavior: "smooth"})}> Return To Top </p> </Grid2>
    </section>
    <footer>
        <FooterELTRP/>
    </footer>
    </>
}