import Grid2 from "@mui/material/Unstable_Grid2"
import { FooterELTRP } from "../layout/footer-ELTRP"
import { HeaderELTRP } from "../layout/header-ELTRP"
import { Link} from "react-router-dom";
import { FeedOutlined, MenuBookOutlined} from "@mui/icons-material"
import { SearchBoxLanding } from "./search-box-landing"
import { Divider } from "@mui/material";
import { httpClient } from "../http/httpClient";
import { useState, useEffect } from "react";


const LandingLinks = () => {

   return <Grid2 container gap={{xs:0, sm:5}} justifyContent={{xs:"space-evenly",sm:"center"}} alignItems="center" marginTop="1rem" marginBottom="1rem"> 
            <Grid2 xs={5.5} sm={2.5} textAlign={"center"}> 
            <Link to={"/search/?sortOrder=-PUBDATE&from=0&size=10"} >
               <FeedOutlined color="secondary" fontSize="large" sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}/> <br/>
               <h4>  Latest Publications </h4> </Link>
            </Grid2>
            <Divider flexItem orientation="vertical"/>
            <Grid2 xs={5.5} sm={2.5} textAlign={"center"}> 
               <Link to={"/dictionary"}> <MenuBookOutlined  color="secondary" fontSize="large" sx={{ stroke: "#ffffff", strokeWidth: 0.5 }}/> <br/>
               <h4> Dictionary </h4> </Link>
            </Grid2>
         </Grid2> 
}

const LandingWelcome = () => {
   const [imageURL, setImageURL]=useState<string>();
   
   useEffect(()=> {
      httpClient.get("/api/v1/data/worldmap", {responseType: "blob", authenticated: true})
          .subscribe(res => {
              setImageURL(URL.createObjectURL(res.data))
          })
  },[])

   return <Grid2 container justifyContent={"center"} xs={12} sm={12}> 
   <Grid2 xs={11} sm={8} xl={6}>
      <h1> Welcome to End-of-life Tyre Research Portal (ELTRP) </h1>
      <p> ELTRP, the End-of-life Tyre Research Portal, brings together articles and reports about recycled tyre rubber.
          It is a platform that aims to support society by enabling access to research into recycled tyre rubber.
          The ELTRP is open to everyone, providing reference material for innovators and businesses as well
          as authorities, municipalities and legislators. The portal also contains a glossary explaining terminology
          and concepts commonly associated with research into recycled tyre rubber.
      </p>
      </Grid2>
   <Grid2 xs={11} sm={8} textAlign={"center"}>
       <h4> Geographical overview of research on recycled tyre rubber  </h4>
      <img id="imageId" alt="world-map" src={imageURL}
      width={"100%"} style={{maxWidth:"1000px"}}/>
   </Grid2>
      
      </Grid2>
}

export const LandingPage = () => {
   return <> 
   <header>
    <HeaderELTRP/>
   </header>
   <section className="landingPageSearch">
      <SearchBoxLanding/> 
   </section>
   <section className="landingPageLinks"> 
      <LandingLinks/>
   </section>
   <Divider flexItem/>
   <section className="landingPageWelcome">
    <LandingWelcome/>
   </section>
   <footer>
    <FooterELTRP/>
   </footer>
   
   </> 
}