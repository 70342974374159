import Axios from "axios-observable"
import URITemplate from "urijs/src/URITemplate";
import URI from "urijs";
import {firstValueFrom, mergeMap} from "rxjs";
import {AxiosObservable} from "axios-observable/lib/axios-observable.interface";
import {API} from "../environment/api";
import {ACCESS_TOKEN, REFRESH_TOKEN} from "../auth/auth.service";
import {AxiosRequestConfig} from "axios";

export interface IHttpOptions {
    pathParams?: { [key: string]: string };
    queryParams?: { [key: string]: any };
    headers?: { [key: string]: string };
    responseType?: XMLHttpRequestResponseType;
    authenticated?: boolean;
}

export class HttpClient {

    constructor() {
        Axios.defaults.headers.post['Content-Type'] = "application/json";
        Axios.defaults.headers.put['Content-Type'] = "application/json";

        Axios.interceptors.request.use((config) => {
            const token = localStorage.getItem(ACCESS_TOKEN);
            if(token) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                }
            }
            return config;
        });

        Axios.interceptors.response.use((response) => {
            return response
        }, (error) => {
            const originalRequest = error.config;
            const refreshToken = localStorage.getItem(REFRESH_TOKEN);
            if(error.response.status === 401 && !originalRequest._retry && refreshToken) {
                originalRequest._retry = true;
                return firstValueFrom(this.refreshRequest().pipe(
                    mergeMap((response) => {
                        const newToken = response.data.accessToken;
                        localStorage.setItem(ACCESS_TOKEN, newToken);
                        return Axios.request(originalRequest)
                    })
                ))

            }
            return Promise.reject(error);
        })
    }

    public transformUrl(url: string, options: IHttpOptions = {}) {
        const parsed = URI(URITemplate(url).expand(options.pathParams || {}).valueOf());

        return parsed
            .search({
                ...parsed.search(true),
                ...(options.queryParams)
            }).valueOf();
    }

    public get(url: string, options?: IHttpOptions) {
        return Axios.get(this.transformUrl(url, options), { responseType: options?.responseType || "json" })
    }

    public put(url: string, data?: any, options?: IHttpOptions) {
        return Axios.put(this.transformUrl(url, options), data)
    }

    public post(url: string, data?: any, options?: IHttpOptions) {
        return Axios.post(this.transformUrl(url, options), data)
    }

    private refreshRequest(): AxiosObservable<any> {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN);
        return Axios.post(API.auth.refresh, {refreshToken}, {_retry:true} as AxiosRequestConfig);
    }

}

export const httpClient = new HttpClient()
