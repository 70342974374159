
import { Add, Edit, Login, Logout} from "@mui/icons-material";
import { AppBar, Box, Button, Divider, Toolbar } from "@mui/material";
import {useContext, useMemo, useState} from "react";
import { useLocation, useNavigate} from "react-router-dom";
import { UserContext } from "..";
import { PdfUploadBox } from "../pdf/pdf-upload";
import {ACCESS_TOKEN, REFRESH_TOKEN, revoke} from "../auth/auth.service";

export const AdminBar = () => {
    
    const context = useContext(UserContext);
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState<boolean>(false)
    const loggedIn = useMemo(() => context.isAuth, [context] )

    const logout = () => {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN);
        if(refreshToken) {
            revoke(refreshToken, () => {})
                .subscribe(() => {})
        }
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        context.dispatch(true)
    }

    const login = () => {
        context.dispatch(true)
    }

    return <Box display={context.env==="ADMIN"? "box": "none"}>
    <AppBar className="adminBar" color="secondary" position="static" style={{padding:"0%", backgroundColor:"white", boxShadow:"none"}}>
        <Toolbar sx={{display:"flex", justifyContent:"flex-end"}}>
            <Box display={((loggedIn && !location.pathname.includes("edit"))? "flex": "none")} flexGrow={1}>
            <Button startIcon={<Add/>} color="secondary" onClick={()=>setOpen(true)}>
                Create New Article
            </Button>
            <Box display={((location.pathname.includes("article") && !location.pathname.includes("edit"))? "flex": "none")}>
                <Button startIcon={<Edit/>} color="secondary" onClick={()=> navigate(location.pathname + "/edit")} size="small">
                    Edit article
                </Button>
            </Box>
            </Box>
            <Box display={(loggedIn? "flex": "none")}>
            <Button startIcon={<Logout/>} color="secondary" onClick={logout} size="small">
                LOGOut
            </Button>
            </Box>
            <Box display={(loggedIn? "none": "flex")} flexBasis="1">
            <Button startIcon={<Login/>} color="secondary" onClick={login} size="small">
                Login
            </Button>
            </Box>
        </Toolbar>
        </AppBar>
        <Divider flexItem/>
        <PdfUploadBox open={open} setOpen={setOpen}/>
        </Box>

}