export const API = {
    article: {
        get: "/api/v1/data/doc/{id}",
        update: "/api/v1/data/doc/{id}/metadata",
    },
    auth: {
        login: "/api/v1/auth/sign-in",
        revoke: "/api/v1/auth/revoke",
        auth: "/api/v1/auth",
        refresh: "/api/v1/auth/refresh",
    },
    search: "/api/v1/data/search",
    autocomplete: "/api/v1/terms/{termId}",
    upload: {
        post: "/api/v1/data/attachment",
        postMultipart: "/api/v1/data/attachmentMultipart",
    },
    create: "/api/v1/data/metadata",
    env: "/api/v1/env",
    dictionary: "/api/v1/data/dictionary",
    map: "/api/v1/data/worldmap",

}