import React, {Dispatch, useEffect, useMemo, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {SearchPage} from "./search/search-page";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {ArticlePage} from "./article/article-page";
import {ArticleEditPage} from "./article/article-edit-page";
import {PdfUpload} from './pdf/pdf-upload';
import {LoginModal} from './login/login-modal';
import {AdminBar} from './layout/admin-bar';
import {ErrorMap} from './article/article.service';
import {getAuth, getEnv} from './auth/auth.service';
import {LandingPage} from './landingpage/landing-page';
import foot2 from "./images/foot2.jpg"
import {Dictionary} from './dictionary/dictionary';
import {ErrorBar} from './layout/error-bar';
import {Copyright} from './link-pages/copyright';
import {DataPolicy} from './link-pages/data-policy';
import {HowToSearch} from './link-pages/how-to-search';
import {About} from "./link-pages/about";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
//https://mui.com/material-ui/customization/color/#picking-colors
const theme = createTheme({
    palette: {
        primary: {
            main: "#E7DCAB",
            light: "#F5F1DC",
            contrastText: "#ffffff"

        },
        secondary: {
            main: "#3b4751",
            light: "#a6a198",
            dark: "#2e2c32",
            contrastText: "#ffffff"
        },
        text: {
            primary: "#000000",
            secondary: "#000000"

        },
        error: {
            main: "#dd88a9",
            contrastText: "#772243"
        }

    },
    shape: {
        borderRadius: 0
    },
    components: {

        MuiChip: {
            defaultProps: {
                variant: "outlined"
            },

            styleOverrides: {
                root: {
                    color: "#2e2c32",
                    boxShadow: "0px 2px 2px 0px rgba(99, 99, 99, 0.1)"

                }

            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {}
        },
        MuiInputBase: {
            defaultProps: {
                autoComplete: "off",
                spellCheck: false
            },
            styleOverrides: {
                root: {
                    backgroundColor: "white"
                },
            },

        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    alignSelf: "start",
                    padding: "0 0.5rem 0 0"
                }
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: "#E7DCAB",
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: (themeParams) => ({
                //Global Style Overrides
                a: {
                    color: "#2249b4",
                    textDecoration: "none",
                    ':visited': {
                        color: "#54278b"
                    },
                    ':hover': {textDecoration: "underline"}
                },
                //Header
                header: {
                    backgroundColor: "white",
                    padding: '1rem 0 1rem 0'
                },
                //Footer
                footer: {
                    backgroundColor: themeParams.palette.primary.light,
                    padding: '1rem 0 1rem 0',

                    marginTop: "auto",

                    fontSize: 12,
                    a: {color: "black", ":visited": {color: "black"}},
                    '.footerSmall': {
                        fontSize: 16
                    },
                },
                '.adminBar': {backgroundColor: "white"},
                //Login Modal
                '.loginModal': {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    border: "none",
                    h2: {
                        margin: "0 0 1rem 0"
                    }
                },
                // PDF Upload
                '.pdfBox': {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    border: "none",
                    h2: {
                        margin: "0 0 1rem 0"
                    }
                },
                //Dictionary
                '.dictionaryHeader': {
                    h1: {fontSize: 28},
                    backgroundColor: themeParams.palette.primary.light,
                    boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
                    padding: "2.5% 0 2.5% 0",
                    small: {
                        margin: "0.5 2rem 0 1rem"
                    },
                    '@media (max-width: 600px)': {
                        small: {fontSize: 16}
                    },
                    '.letterActive': {
                        cursor: "pointer",
                        ":hover": {
                            textDecoration: "underline"
                        }
                    },
                    '.letterDisabled': {
                        cursor: "default",
                        color: "rgba(0,0,0,0.2)"
                    }
                },
                '.dictionaryBody': {
                    margin: "0 0 2rem 0",
                    '@media (max-width: 600px)': {
                        small: {
                            fontSize: 16
                        },
                        h5: {
                            fontSize: 18
                        }
                    },
                    p: {
                        ":hover": {
                            textDecoration: "underline"
                        }
                    }
                },
                '.dictionaryElem': {
                    '@media (min-width: 600px)': {
                        padding: "1rem",
                        borderRadius: 10,
                        boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
                        hr: {
                            borderColor: themeParams.palette.primary.main
                        },
                        div: {
                            padding: "1rem",
                        }
                    }
                },
                'textBody': {
                    minHeight: "70vw"
                },
                //Landing Page
                '.landingPageSearch': {
                    backgroundColor: themeParams.palette.primary.light,
                    backgroundImage: `url(${foot2})`,
                    backgroundSize: "100%",
                    backgroundPosition: "center",
                    boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
                },
                '.landingPageLinks': {
                    a: {
                        textDecoration: "none",
                        color: "black",
                        ':hover': {
                            textDecoration: "underline"
                        }
                    },

                    h4: {
                        marginTop: 0,
                        marginBottom: 0
                    },
                },
                /*'.landingPageWelcome': {
                    '@media (max-width: 600px)':{
                        p:{
                            //fontSize: 16
                        }
                    }
                },*/
                //Search Page
                '.searchBar': {
                    backgroundColor: themeParams.palette.primary.light,
                    boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
                    h4: {
                        margin: 0
                    },
                    ".searchBox": {}
                },
                '.searchBody': {
                    '@media (max-width: 600px)': {
                        padding: "1rem"
                    },
                },
                '.searchResult': {

                    margin: "0 0 2rem 0",
                    padding: " 0 0 1rem 0",
                    ul: {
                        listStyle: "none"
                    },
                    '.selectedAggs': {
                        marginLeft: "2rem",
                        marginTop: "1rem",
                        '@media (max-width: 600px)': {
                            marginLeft: "0.25rem"
                        },
                    },
                    '.searchResultElement': {
                        marginBottom: "2rem",
                        ul: {
                            paddingRight: "1rem",
                        },
                        h3: {
                            fontSize: 20
                        },

                        '@media (max-width: 600px)': {
                            ul: {
                                paddingLeft: "0.25rem"
                            },
                            li: {
                                fontSize: 18,
                            },
                            h3: {
                                fontSize: 22
                            },


                        }
                    },
                    '@media (max-width: 600px)': {
                        borderStyle: "none",
                        padding: "0%",
                    },
                },
                '.searchAggregationSmall': {
                    padding: "0",
                    margin: "0",
                    h4: {
                        margin: "0.5rem 0 0.25rem 0",
                        fontSize: 18
                    },
                    ul: {
                        padding: "0 0 0 1rem"
                    },
                    '.insideList': {
                        li: {
                            padding: "0.25rem ",
                            fontSize: 18
                        }
                    }
                },
                '.searchAggregation': {
                    padding: "0",
                    h4: {
                        margin: "0.5rem 0 0.5rem 0"
                    },
                    ul: {
                        padding: "0",


                    },
                    margin: "0 2rem 2rem 0",

                    ".expand": {
                        cursor: "pointer",
                        display: "flex",
                        color: themeParams.palette.secondary.main,
                        ":hover": {
                            //fontSize: 17,
                            color: "black",
                            textDecoration: "underline",
                            transition: "1s ease-out",
                        },

                    },

                    '.insideList': {
                        li: {
                            padding: "0 0 0 1.5rem",
                        },


                    },
                    li: {
                        margin: "0.5rem 0 0.5rem 0"
                    },
                    '.listItem': {
                        display: "flex",
                        margin: "0 0.5rem 0.5rem 0"
                    }
                },
                //Article Page
                '.articleSummary': {
                    backgroundColor: themeParams.palette.primary.light,
                    boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
                    '.summary': {
                        padding: "1rem 1rem 1rem 1rem",
                        span: {maxHeight: "1rem"},
                        small: {
                            lineHeight: "2rem"
                        },
                        ul: {
                            margin: "0rem 0 0rem 0",
                            fontSize: 16,
                        }
                    },
                },

                '.relatedArticles': {
                    a: {
                        textDecoration: "none",
                        color: "black",
                        ':hover': {
                            textDecoration: "underline"
                        }
                    },

                    h4: {
                        marginTop: 0,
                        marginBottom: 0
                    },
                },
                '.articleBody': {
                    '@media (max-width: 600px)': {
                        h4: {
                            fontSize: 20
                        }
                    },
                    '.chips': {
                        a: {
                            textDecoration: "none", color: "black",
                            ':hover': {
                                textDecoration: "underline"
                            }
                        },
                        '@media (max-width: 600px)': {
                            span: {fontSize: 16}

                        }
                    },
                    padding: "1rem",
                    '.articleInfo': {
                        b: {
                            lineHeight: "2rem"
                        },
                        '@media (max-width: 600px)': {
                            fontSize: 16
                        }
                    }
                },
                //Edit Form
                '.editID': {
                    h1: {fontSize: 28},
                    textAlign: "center",
                    backgroundColor: themeParams.palette.primary.light,
                    boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)",
                    div: {
                        minHeight: "10vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 8
                    }
                },
                '.editForm': {
                    margin: "0 7rem 0 7rem",
                    '.editGroup': {
                        borderLeft: "solid",
                        borderWidth: 5,
                        borderColor: themeParams.palette.secondary.main,
                        padding: "0.5rem",
                        marginTop: "1rem",
                    },
                }


            })
        }
    }

})

//Redux for handling the modal!

export const UserContext = React.createContext({
    isAuth: false,
    env: "",
    authRequired: false,
    snackbar: {message: "", extra: null, statusText: "", open: false, persistent: false},
    handleSnackbar: (() => null) as Dispatch<any>,
    dispatch: (() => null) as Dispatch<any>,
    handleError: (error: ErrorMap) => {
    },
})

export const UserProvider = ({children}: any) => {

    const [isAuth, setIsAuth] = useState(true);
    const [env, setEnv] = useState("")
    const [authRequired, dispatch] = React.useReducer(
        (state: any, newValue: any) => ({...state, ...newValue}), false);
    const [snackbar, handleSnackbar] = React.useReducer(
        (state: any, newValue: any) => ({...state, ...newValue}), {
            message: "",
            extra: null,
            statusText: "",
            open: false,
            persistent: false
        });

    useEffect(() => {
        getEnv().subscribe(r => setEnv(r.data.env))
    }, [])

    useEffect(() => {
        if (env === "ADMIN") {
            getAuth(setIsAuth).subscribe(r => r.status === 200 ? setIsAuth(true) : setIsAuth(false))
        }
    }, [env, authRequired])

    useEffect(() => {
        if (authRequired) {
            setIsAuth(false)
        }
    }, [authRequired])

    const handleError = useMemo(() => (error: ErrorMap) => {
        if (error.statusCode === 401) {
            setIsAuth(false)
        }
        if (error.statusCode !== 401) {
            handleSnackbar({message: error.message, extra: error.extra, statusText: error.statusText, open: true, persistent: error.persistent})
        }
    }, [])

    return (
        <UserContext.Provider value={{isAuth, env, dispatch, handleError, authRequired, snackbar, handleSnackbar}}>
            {children}
        </UserContext.Provider>
    )
}

root.render(
    <React.StrictMode>

        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <UserProvider>
                    <LoginModal/>
                    <AdminBar/>
                    <ErrorBar/>
                    <Routes>
                        <Route path="/" element={<LandingPage/>}/>
                        <Route path={"/search"} element={<SearchPage/>}/>
                        <Route path={"/article/:id"} element={<ArticlePage/>}/>
                        <Route path={"/article/:id/edit"} element={<ArticleEditPage/>}/>
                        <Route path="/login" element={<App/>}/>
                        <Route path="/upload" element={<PdfUpload/>}/>
                        <Route path="/dictionary" element={<Dictionary/>}/>
                        <Route path="/copyright" element={<Copyright/>}/>
                        <Route path="/data-policy" element={<DataPolicy/>}/>
                        <Route path="/how-to-search" element={<HowToSearch/>}/>
                        <Route path="/about" element={<About/>}/>
                    </Routes>
                </UserProvider>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>
);
