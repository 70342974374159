import Grid2 from "@mui/material/Unstable_Grid2"
import { Link } from "react-router-dom"

export const FooterELTRP = () => {
    return <>  <Grid2 container display={{xs:"none", sm:"flex"}}>
            <Grid2 xs={5} xsOffset={1} display="flex" justifyContent={"left"} alignItems={"center"}>
                <ul style={{listStyle:"none", textAlign:"left"}} className="linkList">
                <li> <Link to="/how-to-search"> How to search </Link> </li>
                <li> <Link to="/dictionary">  Dictionary </Link> </li>
                    <li> <Link to="/about">About</Link></li>
            </ul>
            </Grid2>
        <Grid2 xs={5}>
            <ul style={{listStyle:"none", textAlign:"right"}} className="linkList">
                <li> <Link to="/data-policy">  ELTRP data protection policy </Link> </li>
                <li> <Link to="/copyright"> Copyright  </Link> </li>
            </ul>
        </Grid2>
            </Grid2>
            <Grid2 container display={{xs:"flex", sm:"none"}}>
            <Grid2 xs={12} display="flex" justifyContent={"left"} alignItems={"center"}> 
                <ul style={{listStyle:"none", fontSize:16, paddingLeft:"1rem"}}>
                <li><Link to="/data-policy">  ELTRP data protection policy </Link> </li>
                <li> <Link to="/copyright"> Copyright  </Link> </li>
                <li> <Link to="/how-to-search"> How to search </Link> </li>
                <li> <Link to="/dictionary">  Dictionary </Link> </li>
                    <li> <Link to="/about"> About</Link></li>
            </ul>  
            </Grid2>
            </Grid2> </>
}