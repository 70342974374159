import Grid2 from "@mui/material/Unstable_Grid2"
import { FooterELTRP } from "../layout/footer-ELTRP"
import { HeaderELTRP } from "../layout/header-ELTRP"
import {useEffect} from "react";

export const Copyright = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return <>
    <header>
        <HeaderELTRP/>
    </header>
    <section className="editID">
        <div>
            <h1> Copyright </h1>
        </div>
    </section>
    <section className="copyright">
        <Grid2 container xs={11} sm={8} smOffset={2} xsOffset={0.5} mt={2} mb={4}>
            <h2> © Copyright </h2>
            <p> This website is aimed at facilitating access to relevant scientific research in the field of tyre
                end-of-life issues. It has been produced and published by the Swedish Tyre Recycling
                Association (“SDAB”) and is subject to copyright.
            </p>
            <p>
                The scientific papers, to which we link, are made available by their respective publisher and
                on the conditions governing the website where they appear. They are subject to copyright.
                Copying, citing, spreading or other use of texts and/or pictures must be cleared with the
                respective copyright holder.
            </p>
        </Grid2>
         </section>
    <footer>
        <FooterELTRP/>
    </footer>
    </>
}