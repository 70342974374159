import { Aggregation } from "../models/Aggregation";
import { useSearchParams } from "react-router-dom";
import {Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Chip, Fade, IconButton} from "@mui/material";
import { useMemo, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Add, ExpandLess, ExpandMore, Remove } from "@mui/icons-material"

interface IAggregationProps {
    aggregation: Aggregation;
}

type mapping = {
    [key: string]: { name: string, importance: number, open?: boolean }
}
const fieldNameMapping: mapping = {
    LINK_access: { name: "Access type", importance: 1, open: true },
    TYPE: { name: "Type", importance: 3, open: true },
    LANGUAGE: { name: "Language", importance: 7 },
    YEAR: { name: "Year", importance: 4 },
    IDENTIFIER_type: { name: "Identifier", importance: 11 },
    COUNTRY: { name: "Country", importance: 6 },
    SUBJECT: { name: "Keyword", importance: 8 },
    ORGANIZATION_lead: { name: "Organisation", importance: 9 },
    CONTRIBUTOR_lead: { name: "Contributor", importance: 10 },
    AUTHOR: { name: "Author", importance: 5 },
    PERIODICAL_lead: { name: "Periodical", importance: 12 },
    SERIES_lead: { name: "Series", importance: 13 },
    CONFERENCE_lead: { name: "Conference", importance: 14 },
    ATTACHMENT_TYPE: { name: "Attachment", importance: -2, open: false },
    PUBLISHED: { name: "Published", importance: -1, open: false },
    CONTRIBUTOR_type: { name: "Contributor Type", importance: 17 },
    LINK_service: { name: "Service", importance: 2 , open: true}
}

const AggregationList = ({ aggregation }: IAggregationProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(fieldNameMapping[aggregation.field].open)
    const [numVisible, setNumVisible] = useState(3)

    const checked = useMemo(() => searchParams.getAll(aggregation.field), [searchParams, aggregation.field])
    
    const selectAggs = (value: string) => {
        if (!checked.includes(value)) {
            searchParams.append(aggregation.field, value);
            searchParams.set("from", "0")
            setSearchParams(searchParams)
        } else {
            searchParams.delete(aggregation.field)
            searchParams.set("from", "0")
            checked.filter(c => c !== value).forEach(c => searchParams.append(aggregation.field, c));
            setSearchParams(searchParams);
        }
    }

    return <li style={{ listStyle: "none" }}>
        <span style={{ display: "flex" }} onClick={() => {setOpen(!open); setNumVisible(5)}}>
            <IconButton disableRipple size="small" >
                {open ? <Remove fontSize="inherit" /> : <Add fontSize="inherit" />}
            </IconButton>
            <h4> {`${fieldNameMapping[aggregation.field].name}` + (checked.length>0? ` (${checked.length})`  : "")} </h4> 
        </span>
        

        <Fade in={open} unmountOnExit> 
            <ul className="insideList">
                {aggregation.buckets.filter(b => checked.includes(b.key)).map(b =>
                    <li style={{listStyle:"none"}} key={b.key} onClick={() => selectAggs(b.key)}> 
                    <div className="listItem"> 
                    <Checkbox size="small" color="secondary" checked={checked.includes(b.key)} /> 
                    <span> {b.key + " (" + b.doc_count + ")"} </span> </div> </li>
                 )

                }
                {aggregation.buckets.filter(b => !checked.includes(b.key)).slice(0,numVisible).map(b =>
                    <li style={{ listStyle: "none" }} key={b.key} onClick={() => selectAggs(b.key)}>
                        <div className="listItem">
                            <Checkbox size="small" color="secondary" checked={checked.includes(b.key)}  />
                            <span> {b.key + " (" + b.doc_count + ")"} </span> </div> </li>
                    
                )}
                {aggregation.buckets.length>3? 
                numVisible<aggregation.buckets.length? 
                    <div className="expand"  onClick={()=> (numVisible>aggregation.buckets.length+5)? setNumVisible(aggregation.buckets.length): setNumVisible(numVisible + 5)} style={{display:"flex", paddingLeft:"1.75rem", alignItems:"center"}} >  
                      <ExpandMore fontSize="inherit"/>  <i> Show More </i>
                    </div>:
                    <div className="expand" onClick={()=> setNumVisible(3)} style={{display:"flex", paddingLeft:"1.75rem", alignItems:"center"}} >
                      <ExpandLess fontSize="inherit"/> <i> Show Less </i>
                    </div>: <></>
                }
            </ul>
        </Fade>
    </li> 
}

export const SelectedAggregations = ({ aggregations }: IAggregationsProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const selected:[string[]] = [[]] 
    aggregations?.forEach( aggregation => 
         selected.push(searchParams.getAll(aggregation.field)))

    const deleteAggregations = () => {
        const filter: string[] = [];
        const size = searchParams.get("size")
        const query = searchParams.get("query")
        searchParams.forEach((k,v) => filter.push(v))

        for(const f of filter){
            searchParams.delete(f)
        }

        searchParams.set("size", size || "10")
        searchParams.set("query", query || "")
        searchParams.set("from", "0")

        setSearchParams(searchParams)
    }
    
    return <> {selected.filter(a=>a.length>0).map(elem => elem.map(e => <Chip sx={{margin: "0 1% 1% 0"}} label={e} />))
    } {selected.filter(a=>a.length>0).length>0? <Button size={"small"} variant={"outlined"} color="secondary" sx={{margin: "0 1% 1% 0"}}  onClick={deleteAggregations}> Clear Aggregations </Button>:undefined}</>
}

interface IAggregationsProps {
    aggregations: Aggregation[];

}
export const AggregationsList = ({ aggregations }: IAggregationsProps) => {

    const sortedAggregations = aggregations.sort((a, b) => fieldNameMapping[a.field].importance - fieldNameMapping[b.field].importance)

    return (<>

        <Grid2 container justifyContent={{ xs: "center", sm: "left" }}>
            <Grid2 sx={{ display: { xs: "none", sm: "inline" } }}>
                <h2> Aggregations </h2>
                <p> <i> Refine the result </i> </p>

                {/* <SelectedAggregations aggregations={aggregations }/> */}
                <ul className="searchAggregation">
                    {sortedAggregations.filter(a => !(a.buckets?.length < 1)).map(a => <AggregationList key={a.field} aggregation={a}/>)}
                </ul>
            </Grid2>
             
            <Grid2 xs={12} sx={{ display: { xs: "flex", sm: "none" } , padding:"0%"}} >
                <Accordion style={{marginTop: "0.5rem", padding: "0%", minWidth: "200px", width:"100%", height:"100%", boxShadow:"none"}}>
                    <AccordionSummary expandIcon={<ExpandMore />} style={{padding:"0.25rem"}}>
                       <h3 style={{color:"rgba(0,0,0,0.6)", margin:"0.5rem 0 0.5rem 0"}}> AGGREGATIONS </h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul className="searchAggregationSmall">
                            {sortedAggregations.filter(a => !(a.buckets?.length < 1)).map(a => <AggregationList key={a.field} aggregation={a}/>)}
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </Grid2>
        </Grid2>

    </>)


}