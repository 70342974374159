import Grid2 from "@mui/material/Unstable_Grid2";
import {SearchBox} from "./search-box";
import {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {AggregationsList} from "./aggregations";
import {search} from "./search.service";
import {SearchResponse} from "../models/SearchResult";
import {SearchResults} from "./results";
import {Divider, LinearProgress} from "@mui/material";
import { HeaderELTRP } from "../layout/header-ELTRP";
import { FooterELTRP } from "../layout/footer-ELTRP";
import {UserContext} from "../index";


export const SearchPage = () => {
    const [searchParams] = useSearchParams();
    const [results, setResults] = useState<SearchResponse>();
    const context = useContext(UserContext)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const query = searchParams.get("query") || "";
        const from = searchParams.get("from") || "0";
        const size = searchParams.get("size") || "10";
        const sortOrder = searchParams.get("sortOrder") || "SCORE";

        const filters = searchParams
        const filtersAsArray: string[] = [];

        filters.forEach((value, key) => (key!== "from" && key!== "query" && key!=="size" && key!=="sortOrder")? filtersAsArray.push([key, value].join(":")): undefined);
        setLoading(true)
        search(query, filtersAsArray, context.handleError, setLoading, from, size, sortOrder)
                .subscribe(res => {
                    setLoading(false);
                    setResults(res)})
        },
        [searchParams, context.handleError]
    );


    return <> 
    <div>{loading? <LinearProgress/> : null} </div>
    <header>
        <HeaderELTRP/>
    </header>
    <section className="searchBar">
            <Grid2 xs={12} display="flex" justifyContent={"center"} > <SearchBox/> </Grid2>
    </section>
    <section className="searchBody">
        <Grid2 container spacing={0}>
            <Grid2 xs={12} sm={3} xl={2} smOffset={1} xlOffset={2}><AggregationsList aggregations={results?.aggs || []}/></Grid2>
            <Divider flexItem orientation="vertical" style={{display:((results?.hits?.total?.value && results.hits.total.value>0)? "flex": "none")}} />
            <Grid2 xs={12} sm={7} xl={6}><SearchResults results={results?.hits} aggregations={results?.aggs||[]}/></Grid2>
        </Grid2> 
    </section>
    
    <footer>
         <FooterELTRP/> 
    </footer> 
    </>

}
