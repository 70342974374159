import {useContext, useEffect, useState} from "react";
import {Article} from "../models/Article";
import {getArticle} from "./article.service";
import {useParamsStable} from "../utils/hooks/use-params-stable";
import { HeaderELTRP } from "../layout/header-ELTRP";
import { FooterELTRP } from "../layout/footer-ELTRP";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ConnectWithoutContact, Public, PublicOff } from "@mui/icons-material";
import { UserContext } from "..";
import { Chip, Divider, LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { getDictionary } from "../dictionary/dictionary.service";

export const ArticlePage = () => {
    const id = useParamsStable("id");
    const [article, setArticle] = useState<Article>();
    const context = useContext(UserContext);
    const [dictionaryJSON, setDictionaryJSON] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        getDictionary(setLoading).subscribe(a => setDictionaryJSON(a))
    },[])
    
    useEffect(() => {
            setLoading(true)
            id && getArticle(id, context.handleError, setLoading).subscribe(a => {
                setLoading(false); 
                setArticle(a)})
        },[id,context.handleError])

    const LinkElement = (props:{url:string[]}) => {
        return <> {props.url[0]} <a href={props.url[1]} target="_blank" rel="noreferrer"> {props.url[1]} </a> {props.url[2]? "(" + props.url[2] + ")" : ""} </>
    }

    const ArticleSummary = () => {
        return (
        <Grid2 container className="summary" xs={12} sm={8} smOffset={2}> 
            
            <Grid2 xs={12}> 
                {article?.metadata?.commentExt? <span> <b> Comment by ELTPR: </b> {article?.metadata.commentExt} <br/> </span> : <></>} 
                {article?.metadata?.commentInt? <span> <b> Internal Comment: </b> {article?.metadata.commentInt} <br/> </span> : <></>}
                <h1> {article?.metadata?.titleEn? article.metadata.titleEn: "No Title Provided"}</h1>
                <span>
                    <ul style={{listStyle:"none", padding:"0%"}}>
                        <li> {(article?.metadata?.author && article.metadata.author.length>0)? article?.metadata.author?.join("; "): <></>}</li>
                        <li> { article?.metadata?.type? article?.metadata.type: <></>}  </li>
                        <li> {article?.metadata?.year? article?.metadata.year: <></>} </li>
                    </ul>
                </span>
            </Grid2>
            
            {(article?.metadata?.link && article.metadata.link.length>0)?
                (<Grid2 xs={12}  style={{overflowWrap: "break-word"}}>
                    <h3> Access </h3> 
                    <ul> {article?.metadata.link.map(url => <li key={url[1]}> <LinkElement url={url}/> </li>)} </ul> 
                </Grid2>) :
            <></>}
            
            <Grid2 xs={12} justifyContent={"right"} textAlign="right">
                {article?.metadata?.titleTranslated? <small> Title translated by ELTRP </small>: ""}
            </Grid2>
            
            <Grid2 gap={1} xs={12} justifyContent={"right"} display={context.env==="ADMIN"? "flex":"none"} alignItems="center">
                {article?.metadata?.published? <Public fontSize="inherit"/> : <PublicOff fontSize="inherit"/>}
                {article?.metadata?.published? <small> Published </small> : <small> Not Published</small>}
            </Grid2>
            
        </Grid2>
   )}

   

    const ArticleBody = () => {

        return (
            <> 
            <h4> <Divider style={{display:(article?.properties? "box":"none")}}>  ARTICLE PROPERTIES  </Divider> </h4>
            <Grid2 container xs={12} sm={8} smOffset={2} display={context.isAuth? "box": "none"}>
                 <div style={{display:(article?.properties? "box":"none")}}>
                    <span> <b> Filename: </b> {article?.properties?.inputFilename? article.properties.inputFilename: "No filename"} <br/> </span> 
                    <span> <b> Attachment: </b> {(article?.attachment && article.attachment.content_type)? article.attachment.content_type: "No attachment"} <br/></span>
                    <span> <b> Created: </b> {article?.properties?.creDate? article.properties.creDate: "No Creation Date"} <br/></span>
                    <span> <b> Modified: </b> {article?.properties?.modDate? article.properties.modDate: "No Modification Date"} <br/></span>
                    <span> <b> Published: </b> {article?.properties?.pubDate? article.properties.pubDate: "No Publication Date"} <br/> </span>
                </div>
            </Grid2>
            <Divider flexItem variant="middle" style={{margin:"0.5rem 0 0.5rem 0", display:((article?.properties && article?.metadata?.relation)? "box":"none")}}/> 
            
            <Grid2 container xs={12} sm={2} smOffset={5} className="relatedArticles" textAlign="center" justifyContent={"center"} display={article?.metadata?.relation}>
                {(article?.metadata?.relation && article.metadata.relation.length>0)? <Grid2>
                <Link to={`/search/?from=0&size=10&sortOrder=RELATION&RELATION_lead=${article.metadata.relation[0][0]}`}> <ConnectWithoutContact color="secondary" fontSize="large"/> <br/>
                <h4>  Related Articles </h4> </Link>
                <span style={{lineHeight:"1rem"}}> 
                <p style={{fontSize:12}}> This article is part of a collection of article. Click to see related articles. </p></span>
                </Grid2>:<></>}
            </Grid2>
            <Divider flexItem variant="middle" style={{margin:"0.5rem 0 0.5rem 0", display:(article?.metadata?.relation && article.metadata.relation.length>0 && article.metadata?.abstractEn? "box":"none")}}/> 
            <Grid2 container xs={12} sm={8} smOffset={2} className="abstract">
                <Grid2 xs={12}>
                    {article?.metadata?.abstractEn?
                    <> <h3> Abstract [en] </h3>
                    <p> {article?.metadata.abstractEn} </p> </> : <></>}
                </Grid2>
                <Grid2 xs={12} justifyContent={"right"} textAlign="right">
                    {article?.metadata?.abstractTranslated? <small> Abstract translated by ELTRP </small>: ""}
                </Grid2>
                <Grid2 xs={12}>
                    {article?.metadata?.abstractOther? 
                        <> <h3> Abstract [other] </h3>
                        <p> {article?.metadata.abstractOther} </p>
                    </> : <></> }
                    {article?.metadata?.summary? <>
                        <h3> Summary </h3>
                        <p> {article?.metadata.summary} </p>
                    </> : "" }
                </Grid2>
            </Grid2>

        <h4> <Divider style={{display:((article?.metadata?.subject && article.metadata.subject.length>0 )? "box":"none")}}>  KEYWORDS </Divider> </h4>
        
        <Grid2 container xs={12} sm={8} smOffset={2} display={"box"}>
            {article?.metadata?.subject.map((s)=> <Chip label={ 
            Object.keys(dictionaryJSON).map(m => m).includes(s)?
            <Link to="/dictionary" state={{subject: s.toString()}}> <b> {s} </b> </Link>: s} 
            style={{margin:"4px 4px 4px 0"}} clickable={Object.keys(dictionaryJSON).map(m => m).includes(s)}
            /> )}
        </Grid2>
        
       <h4> <Divider style={{display: (article?.metadata? "box": "none")}}>  ARTICLE INFORMATION </Divider> </h4>
        
        <Grid2 container xs={12} sm={8} smOffset={2} className="articleInfo" display={"box"}> 
            <div>
                {(article?.metadata?.titleOther)? (<span> <b> Title other: </b>  {article?.metadata.titleOther} <br/> </span>): <> </>} 
                {(article?.metadata?.author && article.metadata.author.length>0 )? (<span> <b> Author: </b>  {article?.metadata.author?.join("; ")} <br/> </span>): <> </>} 
                {(article?.metadata?.organization && article.metadata.organization.length>0 )? (<span> <b> Organisation: </b> {article?.metadata.organization?.map((s)=>s.filter(Boolean).join(", ")).join("; ")} <br/> </span>) : <></> }
                {article?.metadata?.language? (<span> <b> Original language: </b>{article?.metadata.language} <br/></span>): <></>}
                {(article?.metadata?.country && article.metadata.country.length>0 )? (<span> <b> Country: </b>{article?.metadata.country?.join("; ").replaceAll(",", ", ")} <br/></span>): <></>} 
                {(article?.metadata?.periodical && article.metadata.periodical.length>0 )? (<span><b> Periodical: </b> {article?.metadata.periodical?.map((s)=>s.filter(Boolean).join(", ")).join("; ")} <br/></span>): <></>}
                {(article?.metadata?.series && article.metadata.series.length>0 )? (<span><b> Series: </b> {article?.metadata.series?.map((s)=>s.filter(Boolean).join(", ")).join("; ")} <br/></span>): <></>}
                {(article?.metadata?.conference && article.metadata.conference.length>0 )? (<span> <b> Conference: </b>{article?.metadata.conference?.map((s)=>s.filter(Boolean).join(", ")).join("; ")} <br/></span>): <></>}
                {article?.metadata?.year? (<span><b> Year: </b> {article?.metadata.year} <br/> </span>) : <></>}
                {(article?.metadata?.contributor && article.metadata.contributor.length>0 )? (<span> <b> Contributor: </b> {article?.metadata.contributor?.map(c=> c[0] + (c[1]!==null? " (" + c[1] + ")": "")).join("; ")} <br/></span>): <></> }
                {(article?.metadata?.identifier && article.metadata.identifier.length>0 )? (<span> <b> Identifier: </b> {article?.metadata.identifier?.map((s)=>s.filter(Boolean).join(", ")).join("; ")} <br/> </span>) : <></>} 
                {(article?.metadata?.relation && article.metadata.relation.length>0 )? (<span><b> Related articles: </b>  {article?.metadata.relation?.map((s)=>s.filter(Boolean).join(", ")).join("; ")} </span>): <></>}
            </div>
        </Grid2>

        
    
        </>)
    }

    return <> 
        <div style={{height: "10px"}}>{loading? <LinearProgress/> : null} </div>
        <header>
            <HeaderELTRP/>
        </header>
        <section className="articleSummary">
         <ArticleSummary/> 
        </section>
        <section className="articleBody">
            <ArticleBody/>
        </section>
        <footer>
            <FooterELTRP/>
        </footer>
        </>
}