import {HeaderELTRP} from "../layout/header-ELTRP";
import Grid2 from "@mui/material/Unstable_Grid2";
import {FooterELTRP} from "../layout/footer-ELTRP";
import {useEffect} from "react";

export const About = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return <>
        <header>
            <HeaderELTRP/>
        </header>
        <section className="editID">
            <div>
                <h1> About </h1>
            </div>
        </section>
        <section className="about">
            <Grid2 container xs={11} sm={8} smOffset={2} xsOffset={0.5} mt={2} mb={4}>
                <h2> About ELTRP </h2>
                <p> End-of-life tyre research portal (ELTRP) is the world's first research portal covering reports and
                    articles on recycled tyre rubber. It is a society-supporting research portal aimed at enabling
                    access to published research about the material in areas such as chemical content, performance,
                    material usage, health and environment, and products. ELTRP is open to everyone and is intended to
                    be effectively used by innovators, entrepreneurs, media, academics, authorities, municipalities,
                    legislators, and more.
                </p><p>
                Recycled tyre rubber is a well-research material with a wide range of research reports and articles. The
                idea of ELTRP, however, emerged after identifying a lack of accessibility and a long-standing need to
                gather available research in one unified database.
            </p>
                <p>
                    With support from international tyre recycling organisations, Swedish Tyre Recycling Association
                    (“SDAB”), has taken the initiative to implement and realize the world’s first research portal for
                    recycled tyre rubber. With ELTRP’s support, it becomes possible to use recycled material in a safe
                    and secure manner for humans and the environment, based on the properties inherent in the material.
                    The hope is also that ELTRP will continue to build trust in recycled tyre rubber and elevate the
                    tyre industry as a serious and reliable sector. The goal is for ELTRP to become the definitive
                    database for consolidated research on recycled tyre rubber.
                </p>
                <p>
                    ELTRP is maintained by the Swedish tyre recycling association (SDAB) in collaboration with a
                    post-doctoral researcher (Ph.D) and welcomes contributors of relevant research in the field, with
                    the goal of developing the research portal into the definitive database for consolidated research on
                    recycled tyre rubber. The selection process is managed by a doctoral candidate, with a focus on
                    reports and articles that have broader relevance for the public.
                </p> <p>
                Don’t hesitate to contact <a href={"mailto: info@eltrp.org"}>info@eltrp.org</a> if you have any questions about ELTRP or what to contribute
                with reports and articles.
            </p>
            </Grid2>
        </section>
        <footer>
            <FooterELTRP/>
        </footer>
    </>
}