import { httpClient } from "../http/httpClient";
import { API } from "../environment/api";
import {catchError, map, throwError} from "rxjs";

export function getDictionary(setLoading: (val:boolean) => void ) {
    return httpClient.get(API.dictionary, { authenticated: true })
        .pipe(
            map(res => res.data),
            catchError(err => {setLoading(false); return throwError(err)})
        )
}