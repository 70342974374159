import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { TextField, Button, Paper, Snackbar , Alert, IconButton, Dialog, CircularProgress} from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import { useContext, useEffect, useState } from "react";
import { Field, Form } from "react-final-form"
import { UserContext } from "..";
import {signIn, ISignInRequest, ACCESS_TOKEN, REFRESH_TOKEN} from "../auth/auth.service";

export const LoginModal = () => {
    const [error, setError] = useState<string>();
    const [showPassword, setShowPassword]= useState(false);
    const context = useContext(UserContext);
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const login = (loginData: ISignInRequest) => {
        setLoading(true)
        signIn(loginData, setError, setLoading)
            .subscribe(res => {
                localStorage.setItem(ACCESS_TOKEN, res.data.accessToken);
                localStorage.setItem(REFRESH_TOKEN, res.data.refreshToken);
                setLoading(false)
                setOpen(false)
                context.dispatch(true)
            })
      }



    useEffect(()=> {
        (context.env==="ADMIN" && !context.isAuth)? setOpen(true): setOpen(false)
        
    },[context])

    useEffect(()=>{
        if(!!error){
            setLoading(false)
        }
    },[error])

    return <Dialog open={open} className="loginModal">
        <Paper>
            <Grid2 container justifyContent={"right"}>
                <IconButton onClick={()=> setOpen(false)} > <Close/> </IconButton>
            </Grid2>
            <Grid2 container spacing={1} justifyContent="center" alignItems="center" direction="column" margin="1rem">
                <Grid2>
                    <h2> ADMIN LOGIN </h2>
                 </Grid2>
            <Form onSubmit={login} render={({handleSubmit})=> 
                <form onSubmit={handleSubmit}>
                    <Grid2>
                        <Field name="username">
                            {({input})=> 
                                <TextField color="secondary" autoComplete="on" size="small" fullWidth label="Username" required {...input}/>}
                        </Field> 
                    </Grid2>
                    <Grid2> 
                        <Field name="password">
                            {({input})=> 
                                <TextField size="small" color="secondary" fullWidth type={showPassword? "text": "password"} label="Password" required {...input} 
                                    InputProps={{endAdornment: showPassword? 
                                    <IconButton title="visibility-on" aria-label="visibility-on" size="small" onClick={()=> setShowPassword(!showPassword)}> <Visibility fontSize="inherit"/> </IconButton> :
                                    <IconButton title="visibility-off" aria-label="visibility-off" size="small" onClick={()=> setShowPassword(!showPassword)}> <VisibilityOff fontSize="inherit"/> </IconButton> }}/>}
                        </Field>
                    </Grid2>
                    <Grid2 textAlign={"center"}>
                        {!!error? "Wrong Username/Password" : ""}
                    </Grid2>
                    <Grid2> 
                        <Button fullWidth variant="contained" color="secondary" type="submit"> {loading?  <CircularProgress size={24} color="inherit"/> : "Login"} </Button>
                         
                    </Grid2>   
                </form>}>
            </Form>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(undefined)}>
                <Alert onClose={() => setError(undefined)} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
            </Grid2>
        </Paper>
    </Dialog> 
}