import Grid2 from "@mui/material/Unstable_Grid2"
import { FooterELTRP } from "../layout/footer-ELTRP"
import { HeaderELTRP } from "../layout/header-ELTRP"
import {useEffect} from "react";

export const HowToSearch = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return <>
    <header>
        <HeaderELTRP/>
    </header>
    <section className="editID">
        <div>
            <h1> How to search </h1>
        </div>
    </section>
    <section className="linkPageBody">
        <Grid2 container xs={11} sm={8} smOffset={2} xsOffset={0.5} flexDirection={"column"} mt={2} mb={4}>
            <h3> Basic searching and filtering </h3>
            <p> Find research publications in ELTRP by entering one or many search words and click
                the search icon or press enter to get a list of the most relevant search hits.
                To the left (or top if on a mobile device) you will see some aggregations of the search
                hits (by Year, Access type, Author, Organisation etc) and by clicking the checkboxes in
                front of the aggregation categories you can filter accordingly and narrow your search result.</p>
            <p> ELTRP is designed for searching in English, but it is also possible to search in other languages,
                but then the hits are limited to content in that language.
                Some special English synonym words related to tyres are handled automatically
                (e.g. granulate/crumb and tyre/tire).</p>
            <h3> Advanced searching </h3>
            <p> By using search operators you can make more advanced searches: </p>
                <ul>
                    <li> <b> rubber plastic </b> search for publications containing both (words) rubber and plastic (i.e. "rubber AND plastic")</li>
                    <li> <b> rubber -plastic </b> search for publications containing rubber but not plastic (i.e. "rubber NOT plastic")</li>
                    <li> <b> rubber | plastic </b> search for publications containing rubber or plastic, or both (i.e. "rubber OR plastic")</li>
                    <li> <b> "rubber or plastic" </b> search for publications containing the phrase "rubber or plastic" (i.e. phrase searching)</li>
                    <li> <b> rubber* </b> search for all words that begins with rubber (i.e. truncation)</li>
                    <li> <b> (rubber | plastic) tyre </b> combine operators using parentheses (i.e. "(rubber OR plastic) AND tyre")</li>
                </ul>

        </Grid2>
         </section>
    <footer>
        <FooterELTRP/>
    </footer>
    </>
}