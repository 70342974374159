import { Box, Divider } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2"
import { Link, useLocation } from "react-router-dom";
import ELTRP_Logo_Horizontal_for_white_background from "../Logotyp_ELTRP/ELTRP_Logo_Horizontal_for_white_background.png"

 export const HeaderELTRP = () => {

        const location = useLocation()

        return <> <Grid2 container display={{xs:"none", sm:"flex"}}>
                <Grid2 xsOffset={1} xs={5} display="flex" justifyContent={"left"} alignItems={"center"}> 
                        <Link to="/"> 
                                <img alt={"ELTRP LOGO"} src={ELTRP_Logo_Horizontal_for_white_background} style={{width: "min(300px,35vw)"}}/>
                        </Link>
                </Grid2>
                <Grid2 xs={5} display="flex" justifyContent={"right"} alignItems={"center"}> 
                        <Box display={"flex"} gap={1.5}>
                                <Link to={"/search/?query=&from=0&size=10"} style={{color: "black"}}> <h4> Search </h4> </Link>
                                <Divider flexItem orientation={"vertical"}/>
                        <Link to="/how-to-search" style={{ color: "black", width: "minContent"}}> <h4> How to search </h4> </Link>
                        <Divider flexItem orientation="vertical"/>
                        <Link to="/dictionary" style={{ color: "black"}}>
                        <h4> Dictionary</h4>
                        </Link>
                        </Box>
                </Grid2>
        </Grid2> 
        <Grid2 container display={{xs:"flex", sm:"none"}}>
                <Grid2  xs={12} display="flex" justifyContent={"center"} alignItems={"center"} textAlign="center">
                        <Link to="/"> 
                                <img alt={"ELTRP LOGO"} src={ELTRP_Logo_Horizontal_for_white_background} width="80%"/>
                        </Link>
                </Grid2>
                
                <Grid2 xs={12} display={location.pathname==="/"? "none": "flex"} justifyContent={"center"} alignItems={"center"}>
                        <Grid2 xs={4}  textAlign="center">
                                <Link to="/search/?query=&from=0&size=10" style={{ color: "black"}}> <h4> Search </h4></Link>
                        </Grid2>
                        <Divider flexItem orientation="vertical"/>
                        <Grid2 xs={4}  textAlign="center">
                                <Link to="/how-to-search" style={{ color: "black"}}> <h4> How to search </h4> </Link>
                        </Grid2> 
                        <Divider flexItem orientation="vertical"/>
                        <Grid2 xs={4} textAlign="center">
                        <Link to="/dictionary"> 
                                <h4> Dictionary </h4>
                                </Link>   
                        </Grid2>
                        {/* <Box display="flex" gap={1.5} >
                                <h4> How to Search </h4>
                                <Divider orientation="vertical" flexItem/>
                                <Link to="/dictionary"> 
                                <h4> Dictionary </h4>
                                </Link>
                        </Box> */}
                </Grid2>
        </Grid2> 
        </>
}

// export const HeaderELTRPEdit = () => {
//         return <Grid2 container>
//                 <Grid2 xs={6} display="flex" justifyContent={"left"} alignItems={"center"}> <img alt={"ELTRP LOGO"} src={ELTRP_Logo_Horizontal_for_white_background} width="200"/> </Grid2>
//                 <Grid2 xs={6} display="flex" justifyContent={"right"} alignItems={"center"}> </Grid2>
//         </Grid2>
// }

