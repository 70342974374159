import { Search } from "@mui/icons-material";
import { Box, createTheme, IconButton, TextField } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ThemeProvider } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";


export const SearchBoxLanding = () => {
    const [searchParams] = useSearchParams();
    const [query, setQuery] = useState<string>("");
 
    const navigate=useNavigate()
     
     useEffect(() => {
         setQuery(searchParams.get("query") || "")
     }, [searchParams])
     
     return <ThemeProvider theme={createTheme({
        palette: {primary: {main: "#a6a198"}}, 
        shape: {borderRadius: 0},
        components: {MuiInputBase: {styleOverrides: 
          {root: {backgroundColor: "rgba(255,255,255,0.9)", 
          //":hover":{backgroundColor:"rgba(255,255,255,0.5)", transition: "1s"}
        }, 
          input: {fontSize: 20}}
        }}})}>
       <Grid2 xs={12} display="flex" justifyContent={"center"} >  
          <Box sx={{minWidth: {xs:"300px", sm:"500px"}, padding:"2.5% 0 2.5% 0", margin: {xs:"1.5rem 0 1.5rem 0", sm:"2.5rem 0 2.5rem 0" }}}>
            <span style={{color:"white", textShadow: "1px 0.5px 2px black", fontSize:18}}> <h4 style={{margin:0, fontWeight:100}}> Find research at ELTRP </h4></span>
            <form> 
         <TextField style={{fontSize: "2rem"}} focused variant="outlined" size="medium" fullWidth value={query}
          onChange={e => setQuery(e.target.value)} autoComplete="off" color="primary"
          InputProps={{endAdornment: 
          <IconButton size="large" color="primary" type="submit" onClick={() => {return navigate("/search/?query="+ query + "&from=0&size=10")}}>
            <Search fontSize="large"/> 
          </IconButton>}}
          />
         </form>
         </Box> 
         </Grid2>
         </ThemeProvider> 
    };
 